import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined"
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined"
import { CircularProgress, IconButton } from "@mui/material"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useEffect, useState } from "react"
import MainLayout from "../../layouts/MainLayout"
import api from "../../services"
import { phoneMask } from "../../utils/masks"
import { formatDate } from "../../utils/format"
import ModalCheck from "./components/modal"
import ExportModal from "./components/ExportModal"
import * as S from "./style"
import { Link } from "react-router-dom"
import Papa from "papaparse"
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined"
import Tooltip from "@mui/material/Tooltip"

function Home() {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [data, setData] = useState([])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [paramsName, setParamsName] = useState({ name: "" })
  const [paramsEmail, setParamsEmail] = useState({ email: "" })
  const [body, setBody] = useState({
    type: "",
    content: "",
  })

  function openExportModal() {
    setExportModalOpen(true)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  function openModalCheck(row) {
    setData(row)
    setModalIsOpen(true)
    setAnchorEl(null)
  }

  async function getData() {
    if (body.type === "name") {
      setParamsName({
        name: body.content,
      })
    } else if (body.type === "email") {
      setParamsEmail({
        email: body.content,
      })
    }

    try {
      setLoading(true)
      const res = await api.get(
        "/api/admin/users/list",
        body.type === "name" ? { params: paramsName } : { params: paramsEmail }
      )

      setUsers(res.data)
      console.log(res.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const generateCSV = (selectedFields) => {
    const csvData = users.map((consumer) =>
      selectedFields.reduce((obj, field) => {
        obj[field] = consumer[field]
        return obj
      }, {})
    )

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", "anunciantes.csv")
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <MainLayout title="Cadastro de usuários do App">
      <S.Header>
        <S.Select onChange={(e) => setBody({ ...body, type: e.target.value })}>
          <option value="" selected>
            Selecionar filtro
          </option>
          <option value="name">Nome</option>
          <option value="email">Email</option>
        </S.Select>
        <S.Input
          placeholder="Busque pelo nome"
          onChange={(e) => setBody({ ...body, content: e.target.value })}
        />
        <S.Button disabled={loading} onClick={() => getData()}>
          Pesquisar
        </S.Button>
        <S.Button disabled={loading} onClick={() => openExportModal()}>
          Exportar para CSV
        </S.Button>
      </S.Header>

      <TableContainer component={Paper}>
        {loading ? (
          <S.ContainerLoading>
            <CircularProgress style={{ color: "#919DF2" }} />
          </S.ContainerLoading>
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Data de Criação</TableCell>
                <TableCell>Cidade</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Plano</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {users?.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="item">
                    <Link
                      to={`/detail/anunciante/${item.id}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {item?.name ? item?.name : "-"}
                    </Link>
                  </TableCell>

                  <TableCell>{item?.email ? item?.email : "-"}</TableCell>
                  <TableCell>
                    {item?.telefone ? phoneMask(item?.telefone) : "-"}
                  </TableCell>
                  <TableCell>
                    {item?.created_at
                      ? formatDate(item.created_at.split("T")[0])
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {item?.cidade?.nome ? item?.cidade?.nome : "-"}
                  </TableCell>
                  <TableCell>
                    {item?.estado?.nome ? item?.estado?.nome : "-"}
                  </TableCell>
                  <TableCell>
                    {item?.plan_id === 2
                      ? "PRO"
                      : item?.plan_id === 1
                      ? "FREE"
                      : "-"}
                  </TableCell>
                  <TableCell onClick={() => openModalCheck(item)}>
                    <Tooltip title="Alterar plano do usuário">
                      <IconButton
                        aria-label="alterar plano"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        style={{ padding: 0 }}
                      >
                        <EmojiEventsOutlinedIcon /> {/* Ícone de medalha */}
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem>
                        <ZoomInOutlinedIcon style={{ marginRight: 5 }} />
                        Atualizar Plano
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {modalIsOpen ? (
        <ModalCheck
          setModalIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          data={data}
        />
      ) : (
        ""
      )}

      {exportModalOpen && (
        <ExportModal
          exportModalOpen={exportModalOpen}
          setExportModalOpen={setExportModalOpen}
          onExport={generateCSV}
        />
      )}
    </MainLayout>
  )
}

export default Home
